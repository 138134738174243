@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

.jackpot {
  background-image: url("../images/bg-jackpot.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-color: #200A1E;
  height: 100%;
  position: relative;
  overflow: hidden;

  //&__coins {
  //  background-image: url("../images/coins.png");
  //  background-size: 100% auto;
  //  position: absolute;
  //  left: 0;
  //  top: 0;
  //  height: 100%;
  //  width: 100%;
  //}

  &__container {
    max-width: 1280px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      width: 100%;
    }
  }

  &__current, &__live {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__live {
    margin-bottom: 20px;
  }

  &__header {
    @include fluid-type(320px, 1280px, 15px, 36px);
    text-align: center;
    color: white;
    font-family: 'Roboto', sans-serif;
    margin-top: 5%;
    margin-bottom: 4%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    overflow: hidden;

    &:before,
    &:after {
      background-color: #DBC881;
      content: "";
      display: inline-block;
      height: 2px;
      position: relative;
      vertical-align: middle;
      width: 50%;
    }
    &:before {
      right: 0.5em;
      margin-left: -50%;
    }
    &:after {
      left: 0.5em;
      margin-right: -50%;
    }
  }

  &__block {
    display: flex;
    width: 90%;
    justify-content: space-between;
    padding-left: 150px;
    padding-right: 150px;
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: auto;
    align-items: center;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &--dk {
      background-image: url("../images/bg-dk.svg");
      height: 19vw;
      max-height: 249px;
      width: 100%;
    }

    &--king-kong {
      background-image: url("../images/bg-king-kong.svg");
      height: 14vw;
      max-height: 181px;
    }

    &--fairy-ring {
      background-image: url("../images/bg-fairy-ring.svg");
      height: 14vw;
      max-height: 181px;
    }

    &--blazing {
      background-image: url("../images/bg-blazing.svg");
      height: 14vw;
      max-height: 181px;
    }

    &--mystery {
      background-image: url("../images/bg-mystery-jackpot.svg");
      height: 30vw;
      max-height: 419px;
    }

    &--poker {
      background-image: url("../images/bg-poker.svg");
      height: 30vw;
      max-height: 419px;
    }

  }

  &__content {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 5vw;
    padding-right: 5vw;

    @media screen and (min-width: 1280px) {
      padding-left: 50px;
      padding-right: 50px;
    }

    &--big {
      padding-left: 10vw;
      padding-right: 10vw;
      @media screen and (min-width: 1280px) {
        padding-left: 100px;
        padding-right: 100px;
      }
    }

    &--grid {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
    }
  }

  &__title {
    font-family: 'Roboto Condensed', Sans-Serif, serif;
    font-weight: bold;
    color: white;
    margin: 0;
    text-shadow: 0 3px 1px rgba(0,0,0,0.65);
    -webkit-font-smoothing: antialiased;
    @include fluid-type(320px, 1280px, 12px, 28px);

    &--big {
      @include fluid-type(320px, 1280px, 14px, 50px);
      //font-size: 50px;
      text-transform: uppercase;
    }
  }

  &__prize {
    margin: 0;
    font-family: 'Roboto Condensed', Sans-Serif, serif;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
    position: relative;
    background-image: linear-gradient(to bottom, #b18e51, #ffff97 47%, #bfad6f 49%, #e7d288);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-font-smoothing: antialiased;
    font-size: 70px;
    transform: translateZ(1px); /* fix Safari on Desktop */
    @include fluid-type(320px, 1280px, 15px, 70px);

    &--small {
      font-size: 45px;
      @include fluid-type(320px, 1280px, 10px, 45px);
      background-image: linear-gradient(to bottom, #B36F54, #FDD2BA 47%, #C5804F 49%, #F6B38C);
    }

    &--big {
      @include fluid-type(320px, 1280px, 20px, 80px);
    }

    &--blue {
      background-image: linear-gradient(to bottom, #7C8A8E, #E8F5F9 47%, #B1C4CB 49%, #FFFFFF);
    }

    @media all and (-ms-high-contrast:none) {
      background: none;
      color: #DBC881;
      &--blue {
        color: #E2EDF5;
      }

      &--small {
        color: #F3C39F;
      }
    }
  }

  &__place {
    color: #CCC1C8;
    @include fluid-type(320px, 1280px, 10px, 18px);
    font-family: 'Roboto', Sans-Serif, serif;
    margin: 0;

  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 2.5%;
    @supports (-ms-ime-align:auto) {
      margin-top: 20px
    }
  }
}